import {
  CampaignDetails,
  CampaignDetailsForUser,
} from '../../schema/campaign';
import { CakeApiBase } from '../CakeApiBase';

export class CampaignApi extends CakeApiBase {
  async getDetailsForUser() {
    return this.requestManager.get<CampaignDetailsForUser>('/campaign/user');
  }

  async getDetails() {
    return this.requestManager.get<CampaignDetails>('/campaign');
  }
}
