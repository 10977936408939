const appConfig = {
  APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  ACCEPTED_FREEZE_DURATIONS_MONTHS: [1, 3, 6, 12, 24, 36, 60, 120],
  ACCESS_TOKEN_MAX_AGE: 15 * 60, // 15 mins
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT || '/api/',
  MERKLE_TREE_SERVICE: process.env.REACT_APP_MERKLE_TREE_SERVICE || 'https://merkle.bake.io',
  APPRENTICE_BAKER_AMOUNT: 10000,
  AVAILABLE_FREEZE_AMOUNT_MULTIPLIER: 2,
  CAKEDEFI_HOST: process.env.CAKEDEFI_HOST || 'bake.io',
  COUNTRIES_EXCLUDED_FROM_TWILIO: ['86', '84'], // Twilio can't send SMS to Vietnamese and Chinese phone numbers
  DEFAULT_AUTHENTICATED_RESTRICTED_REDIRECT: '/',
  DEFAULT_CRYPTO_DECIMALS: 8,
  DEFAULT_DEXSWAP_RATE: 0.005,
  DEFAULT_HTTP_TIMEOUT: 240000,
  DEFAULT_LISTING_PAGE_SIZE: 5,
  DEFAULT_LOCALE: 'en-US',
  DEFAULT_PAGINATION_SIZE: 5,
  FRIENDLY_DECIMALS: 5,
  ID_VALIDITY_MONTHS: 6,
  MASTER_BAKER_AMOUNT: 100000,
  MAX_YEAR: 2200,
  MIN_AGE: 18,
  MIN_ALLOCATED_AMOUNT: 0.00001,
  MIN_YEAR: 1900,
  OVERLOAD_STATUS: 429,
  PING_CLIENT_HOST_INTERVAL: 3000,
  POLLING_REFRESH_DELAY: process.env.REACT_APP_POLLING_REFRESH_DEPLAY || 10000, // 10 seconds
  PRIVATE_COINS: ['SINS', 'NRG'],
  REGULARRECAPTCHAKEY: process.env.REACT_APP_RECAPTCHA_KEY_REGULAR,
  SECURERECAPTCHAKEY: process.env.REACT_APP_RECAPTCHA_KEY_SECURE,
  REFRESH_TOKEN_MAX_AGE: 14 * 24 * 60 * 60, // 2 weeks
  REMEMBER_ME_MAX_AGE: 365 * 24 * 60 * 60, // 1 year
  MIN_PASSWORD_LENGTH: 12,
  MAX_PASSWORD_LENGTH: 128,
  ZXCVBN_SCORE_THRESHOLD: 3,
  SNAPCHAT_CLIENT_ID: '00991a96-ba47-4f23-bd37-5a4534dd6c11',
  IS_DISABLE_ALL_SWAP: false,
  IS_HARDFORK_PHASE_2: false,
  IS_HARDFORK_PHASE_3: true,
  LOGIN_URL_TO_RAZER_HEAD: 'https://oauth2.razer.com/authorize_openid?response_type=code&l=en&scope=openid+profile&client_id=',
  LOGIN_URL_TO_RAZER_TAIL: `&state=xyz&redirect_uri=${window.location.origin}/razer/success`,
  SUBSCRIPTION_SERVICE: process.env.REACT_APP_SUBSCRIPTION_SERVICE === 'true',
  SUBSCRIPTION_SERVICE_PRICING_TABLE: process.env.REACT_APP_SUBSCRIPTION_SERVICE_PRICING_TABLE === 'true',
  SUBSCRIPTION_SERVICE_SUPPORT_WHATSAPP: '+6588154822',
  SUBSCRIPTION_SERVICE_SUPPORT_EMAIL: 'bakepro@bake.io',
  SUBSCRIPTION_SERVICE_SUPPORT_TELEGRAM: 'https://t.me/+Ts-iDNyRImpkZWNk',
  DD_ENABLE: process.env.REACT_APP_DD_ENABLE,
  DD_APPLICATION_ID: process.env.REACT_APP_DD_APPLICATION_ID,
  DD_CLIENT_TOKEN: process.env.REACT_APP_DD_CLIENT_TOKEN,
  DD_SERVICE: process.env.REACT_APP_DD_SERVICE,
  DD_ENV: process.env.REACT_APP_DD_ENV,
  DD_VISIBILITY_LEVEL: process.env.REACT_APP_DD_VISIBILITY_LEVEL,
};

export default appConfig;
