import { Action, combineReducers } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import promotionCampaignSlice from 'referral/promotionCampaignSlice';
import dexModalReducer from 'wallet/components/DEXModal/state/dexModalSlice';
import transactionQueueReducer from 'wallet/components/TransactionQueue/transactionQueueSlice';
import popupBannerReducer from 'common/components/PopupBanner/popupBannerSlice';
import sprodReducer from '../views/components/Sprod/state/sprodSlice';
import walletReducer from '../wallet/walletSlice';
import fiatBuyModalReducer from '../wallet/components/FiatBuy/fiatBuyModalSlice';
import currencyReducer from '../currency/currencySlice';
import userReducer from '../user/userSlice';
import settingsReducer from '../user/settingsSlice';
import stakingReducer from '../staking/StakingPageSlice';
import navigationReducer from '../navigation/navigationSlice';
import manageLiquidityModalReducer from '../liquidity-mining/components/ManageLiquidityModal/manageLiquiditySlice';
import addLiquidityModalReducer from '../liquidity-mining/AddLiquidityModal/addLiquiditySlice';
import swapCoinModalReducer from '../wallet/components/SwapModal/swapCoinModalSlice';
import allocateModalReducer from '../wallet/components/AllocateModal/allocateModalSlice';
import liquidityMiningReducer from '../liquidity-mining/liquidityMiningSlice';
import removeLiquidityModalReducer from '../liquidity-mining/components/RemoveLiquidityModal/removeLiquidityModalSlice';
import languageRegionSettingsReducer from '../user/LanguageRegionSettings/languageRegionSettingsSlice';
import accountVerificationReducer from '../user/AccountVerification/accountVerificationSlice';
import authReducer from '../views/Pages/Authentication/authSlice';
import userIdentifiableInformationReducer from '../views/components/PII/identifiableInformationSlice';
import securitySettingsReducer from '../user/Security/securitySettingsSlice';
import exportTransactionModalSlice from '../transactions/export-modal/exportTransactionModalSlice';
import transactionPageSlice from '../transactions/transactionPageSlice';
import referralSlice from '../referral/referralSlice';
import freezerCalculatorReducer from '../freezer/FreezerCalculator/freezerCalculatorSlice';
import freezeCoinInfoReducer from '../freezer/FreezerCalculator/freezerCoinInfoSlice';
import newFreezerReducer from '../freezer/NewFreezer/newFreezerSlice';
import networkErrorReducer from '../networkError/networkErrorReducer';
import referralCodeReducer from '../referral/referralCodeSlice';
import freezerHighlightsReducer from '../freezer/FreezerHighlights/freezerHighlightsSlice';
import whitelistAddressesReducer from '../user/WhitelistAddresses/whitelistAddressesSlice';
import userAppPreferenceReducer from './userAppPreferenceSlice';
import zendeskReducer from '../balance-bar/components/ZendeskSlice';
import myInfoReducer from '../user/myInfoSlice';
import learnEarnReducer from '../learn-earn/learnEarnSlice';
import preSwapModalReducer from '../wallet/components/PreSwapModal/preSwapModalSlice';
import voucherReducer from '../voucher/voucherSlice';
import oauthReducer from '../user/oauthSlice';
import { GlobalActionTypes } from './actions';
import campaignSliceReducer from '../campaigns/campaignsSlice';
import redeemSliceReducer from '../user/RedeemVoucher/redeemSlice';
import transparencyReducer from '../transparency/transparencySlice';
import incomingDepositsReducer from '../incoming-deposits/incomingDepositsSlice';
import SubscriptionReducer from '../subscription/subscriptionSlice';

const appReducer = combineReducers({
  wallet: walletReducer,
  fiatBuyModal: fiatBuyModalReducer,
  swapCoinModal: swapCoinModalReducer,
  freezerCoinInfo: freezeCoinInfoReducer,
  currency: currencyReducer,
  user: userReducer,
  settings: settingsReducer,
  staking: stakingReducer,
  navigation: navigationReducer,
  allocateModal: allocateModalReducer,
  manageLiquidityModal: manageLiquidityModalReducer,
  addLiquidityModal: addLiquidityModalReducer,
  liquidityMining: liquidityMiningReducer,
  removeLiquidityModal: removeLiquidityModalReducer,
  languageRegionSettings: languageRegionSettingsReducer,
  accountVerification: accountVerificationReducer,
  securitySettings: securitySettingsReducer,
  exportTransactionModal: exportTransactionModalSlice,
  transactions: transactionPageSlice,
  referral: referralSlice,
  freezerCalculator: freezerCalculatorReducer,
  newFreezer: newFreezerReducer,
  networkError: networkErrorReducer,
  referralCode: referralCodeReducer,
  freezerHighlights: freezerHighlightsReducer,
  whitelistAddresses: whitelistAddressesReducer,
  userAppPreference: userAppPreferenceReducer,
  promotionCampaign: promotionCampaignSlice,
  dexModal: dexModalReducer,
  zendesk: zendeskReducer,
  myInfo: myInfoReducer,
  learnEarn: learnEarnReducer,
  transactionQueue: transactionQueueReducer,
  preSwapModal: preSwapModalReducer,
  voucher: voucherReducer,
  oauth: oauthReducer,
  userIdentifiableInformation: userIdentifiableInformationReducer,
  auth: authReducer,
  popupBanner: popupBannerReducer,
  campaigns: campaignSliceReducer,
  redeem: redeemSliceReducer,
  sprodMain: sprodReducer,
  transparency: transparencyReducer,
  incomingDeposits: incomingDepositsReducer,
  Subscription: SubscriptionReducer,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state: RootState, action: Action) => {
  if (action.type === GlobalActionTypes.LOGOUT) {
    const partialState: RootState = {
      currency: state.currency,
      userAppPreference: state.userAppPreference,
    } as any;
    return appReducer(partialState, action);
  }

  return appReducer(state, action);
};

export default rootReducer;

export const useCakeSelector: TypedUseSelectorHook<RootState> = useSelector;
export type ThunkApiConfig = { state: RootState };
