import { StandaloneModalContext } from 'modals/StandaloneModalContext';
import { StandaloneModalType } from 'modals/useStandaloneModal';
import React, { useContext } from 'react';
import NovaLoginModal from './modals/NovaLoginModal';
import StandaloneModalDialog from './StandaloneModalDialog';

/**
 * Standalone modals that are shown concurrently with other modals
 * This will overlap with the main ModalContext modals
 */

const StandaloneModals = () => {
  const { closeModal } = useContext(StandaloneModalContext);

  return (
    <>
      <StandaloneModalDialog
        modalType={StandaloneModalType.MOBILE_POPUP_ON_LOGIN}
        zIndex={1100}
      >
        <NovaLoginModal onClose={() => closeModal(StandaloneModalType.MOBILE_POPUP_ON_LOGIN)} />
      </StandaloneModalDialog>
      {/* Add more modals here wrapped with StandaloneModalDialog */}
    </>
  );
};

export default StandaloneModals;
