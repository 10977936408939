import ReactGA from 'react-ga';

export function initAnalytics() {
  ReactGA.initialize('UA-145606297-1');
  window.addEventListener(
    'hashchange',
    () => {
      ReactGA.set({ page: window.location.hash });
      ReactGA.pageview(window.location.hash);
    },
    false,
  );
}
